import {CoherenceLoading, CoherenceNotificationType, IErrorStateProps, IListItem, IListItemStyleProps, IListItemStyles, ListItem, LoadingState } from "@coherence-design-system/controls";
import {
  FontWeights,IconButton,FontSizes,
  Pivot,
  IStyleFunctionOrObject,
  flatten,
  IComboBox,
  IDropdown,
  List,
  PivotItem, 
} from "@fluentui/react"; 
import React, { FormEvent, useEffect, useMemo, useState } from "react"; 
import { useLocation, useNavigate } from "react-router-dom";
import { SvgIconButtonShim } from '@coherence-design-system/icon';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { EmptyState, EmptyStateIconType } from '@coherence-design-system/empty-state'; 
import { IResult, ICategory } from "@coherence-design-system/enhanced-search";
import { IPaginationProps, OverflowOption, Pagination } from '@coherence-design-system/pagination';
import { DOMEApiHelper, isEmpty } from "../../utils/DOMEApiHelper";
export const HeaderSearchResults= () => { 
  const [searchResultData, setSearchResultData] = useState<any>([]);  
  const [resultsByCategory, setResultsByCategory] = useState<any>([]);  
  const [searchText, setSearchText] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("");
  const[hasError, setHasError] = useState<boolean>(false); 
  //toaster code Start
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState(false);
  //toaster code end
  let navigate = useNavigate();
  const location = useLocation();
useEffect(() => {

  if(location.state?.searchData){
    setSearchText(location.state.searchData.searchText); 
    setSearchType(location.state.searchData.searchType);
    onSearch(location.state.searchData.searchText,location.state.searchData.searchType);
  }
}, []); 

//START --Search API Call

const onSearch = (searchTextValue: string,searchType:string) => {
  setIsLoaded(true);
  let SearchInput ={
    "SearchType": searchType,
    "SearchValue": searchTextValue
  }    
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(SearchInput),
      mode: 'cors'
  }; 
   DOMEApiHelper('PORItems/GlobalSearch', requestOptions)
       .then(res => {         
           let porItemsList = res; 
           setHasError(porItemsList.length === 0); 
          setIsLoaded(false);

          setResultsByCategory([
            {
              category: 'PORID',
              items:porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.PORID) && index === self.findIndex((t:any) => t.PORID === item.PORID)
              ) 
             },
            {
              category: 'Title',
              items: porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.PORID) && index === self.findIndex((t:any) => t.PORID === item.PORID)
              ) 
             },
             {
              category: 'CTID',
              items:porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.CTID) && index === self.findIndex((t:any) => t.CTID === item.CTID)
              )
             },
             {
              category: 'MILAID',
              items:porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.MILAID) &&  index === self.findIndex((t:any) => t.MILAID === item.MILAID)
              )
             } ,
             {
              category: 'OneMapID',
              items:porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.OneMapID) &&  index === self.findIndex((t:any) => t.OneMapID === item.OneMapID)
              )
             } ,
             {
              category: 'PFAM',
              items:porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.PFAM) && index === self.findIndex((t:any) => t.PFAM === item.PFAM)
              )
             } ,
             {
              category: 'SKU',
              items:porItemsList.filter((item:any, index:any, self:any) =>
                !isEmpty(item.SKU) && index === self.findIndex((t:any) => t.SKU === item.SKU)
              )
             } 
          ])
       }).catch(res => {
           setToastMessage(res.toString());
           setToastType(CoherenceNotificationType.ERROR);
           setTimeout(() => {
               setShowToast(true);
           }, 5000);
           setIsLoaded(false);
           
       }); 

}

//END API Call

// -- START -- Pivots code
interface IPortalSearchResult extends IResult {
  ID:string;
  PORID: string; 
  Title: string;
  CTID: string;
  MILAID: string; 
  OneMapID: string;
  PFAM: string; 
  SKU: string; 
}

interface IPortalSearchResultsByCategory extends Omit<ICategory, 'items'> {
  items: IPortalSearchResult[];
}

interface ICategoryPivotsProps {
  activePivotCategory: string;
  onPivotChanged: (item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) => void;
  resultsByCategory: IPortalSearchResultsByCategory[];
}

const CategoryPivots: React.FC<ICategoryPivotsProps> = (props: ICategoryPivotsProps) => {
  const { activePivotCategory, onPivotChanged, resultsByCategory } = props;

  const allItems = useMemo(() => {
    return flatten(resultsByCategory.map((result) => result.items)).sort((a, b) => b.ID.localeCompare(a.ID));
  }, [resultsByCategory]);

  if (!resultsByCategory.length) return null;

  const pivotItems = 
  [{ category: 'All', items: allItems }, ...resultsByCategory]
  .filter((category) => 
    searchType === 'All' || category.category === searchType
  ) // Filter based on searchType
  .map((category, index) => {
    const key = `${category.category.trim().replace(/\s/g, '-')}_${index}`;
    const uniqueAllItems = category.items.filter(
      (value, index, self) => 
        index === self.findIndex((t) => t.ID === value.ID) // Step 3: Ensure unique PORID
    );
    return (
      <PivotItem
        ariaLabel={category.category}
        headerText={`${category.category} (${uniqueAllItems.length})`}
        itemKey={key}
        key={key}
      />
    );
  }); 

  // OverflowBehavior needs to be none when there are no overflow items.
  return (
    <Pivot
      aria-label="Search results categories"
      onLinkClick={onPivotChanged}
      selectedKey={activePivotCategory}
      overflowBehavior="none"
      overflowAriaLabel="more categories"
    >
      {pivotItems}
    </Pivot>
  );
};
// -- END -- Pivots code

// -- START -- Styles
const listItemStyles = (): IStyleFunctionOrObject<IListItemStyleProps, IListItemStyles> | undefined => {
  return {
    root: {
      borderBottom: `1px solid ${CoherenceTheme.semanticColors.bodyFrameDivider}`,
      paddingBlockStart: '8px',
      paddingBlockEnd: '8px',
      paddingInlineStart: '4px',
      paddingInlineEnd: '16px',
      pointerEvents: 'none',
      ':hover, :focus': {
        backgroundColor: CoherenceTheme.palette.neutralLighter
      },
      ':active': {
        backgroundColor: CoherenceTheme.palette.neutralLight
      },
      ':focus': {
        outlineColor: CoherenceTheme.palette.neutralSecondary
      }
    },
    subComponentStyles: {
      headerStyles: {
        title: {
          pointerEvents: 'auto'
        }
      },
      bodyStyles: {
        description: { width: 'max-content', pointerEvents: 'auto' },
        additionalText: {
          overflow: 'hidden',
          wordWrap: 'break-word',
          boxOrient: 'vertical',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '2'
        }
      }
    }
  };
};

const headerSearchResultsStyle = {
  display: 'flex',
  gap: '12px',
  marginLeft: '-8px',
  marginBottom: '24px',
  fontWeight: FontWeights.bold,
  fontSize: FontSizes.size28,
  alignItems: 'center'
};

const resultsBoundariesMessageStyle = () => {
  return {
    fontSize: FontSizes.size14,
    lineHeight: '20px',
    color: CoherenceTheme.semanticColors.inputPlaceholderText,
    padding: '16px 0'
  };
};

const searchResultsStyle = {
  margin: 'auto',
  marginTop: '48px',
  marginBottom: '24px',
  maxWidth: '900px'
};
// -- END -- Styles 
// -- START -- Types
interface ISearchResult {
  /**
   * ErrorState component props.
   */
  errorStateProps?: IErrorStateProps;
  /**
   * Function that triggers at the click of back button.
   */
  /**
   * Flag to indicate if the error page should show.
   */
  hasError: boolean;
  /**
   * Flag to indicate if there are no results for query.
   */
  isEmpty: boolean;
  /**
   * Flag to indicate loading state.
   * @default false
   */
  isLoading?: boolean;
  /**
   * Search results from search query
   */
  results: IPortalSearchResultsByCategory[];
  /**  
   * Search text to display in the search results pattern.
   */
  searchText: string; 
}
// -- END -- Types

// -- START -- Main search results pattern
const goBack = () => {
  navigate(-1);
};
const HeaderSearchResults = ({ goBack }: { goBack: () => void; }) => {
  return (
    <div style={headerSearchResultsStyle}>
      <SvgIconButtonShim
        buttonAs={IconButton}
        iconProps={{ iconName: 'Back' }}
        onClick={goBack}
        styles={{ root: { color: CoherenceTheme.semanticColors.link } }}
        svgIconsEnabled={CoherenceTheme.svgIconsEnabled}
        title="Back"
      />
      Search results
    </div>
  );
};

const ResultsBoundariesMessage = ({
  metaInformation: { resultsRange, totalResultsPerCategory },
  searchText 
}: {
  metaInformation: { resultsRange: string; totalResultsPerCategory: number };
  searchText: string; 
}) => {
  const metaMessage =
    totalResultsPerCategory < 10
      ? `Showing ${resultsRange} results for "${searchText}"`
      : `Showing ${resultsRange} of ${totalResultsPerCategory} results for "${searchText}"`;
  return <p style={resultsBoundariesMessageStyle()}>{metaMessage}</p>;
};

const ResultList = ({
  indexedResults 
}: {
  indexedResults: IPortalSearchResult[];  
}) => {
  type ReactMouseEvent = React.MouseEvent<HTMLAnchorElement | HTMLElement | HTMLButtonElement, MouseEvent>;

  // const handleOnClick = (event: ReactMouseEvent, item: IPortalSearchResult | undefined) => { 
  //   navigate(`/PORDetails/${item?.PORID}`);
  //   event.stopPropagation();
  //   event.preventDefault();
  // };

  return (
    <List
      items={indexedResults}
      onRenderCell={(item) => { 
        return (
          <ListItem
            {...(item as IListItem)}
             additionalText= {item?.PORID? "PORID: "+item!.PORID:""} 
            title={{
              href: `/PORDetails/${item?.PORID}`, // Dynamically set href
              target: "_blank", // Open link in a new tab
              children: item!.Title
              // onClick: (event: ReactMouseEvent) => handleOnClick(event, item)
            }}
            description={[
              item?.CTID ? `CTID: ${item.CTID}` : "",
              item?.MILAID ? `MILAID: ${item.MILAID}` : "",
              item?.OneMapID ? `OneMapID: ${item.OneMapID}` : "",
              item?.PFAM ? `PFAM: ${item.PFAM}` : "",
              item?.SKU ? `SKU: ${item.SKU}` : "",
            ]
              .filter(Boolean) // Remove empty strings
              .join(" | ")} // Join with separator 
            iconProps={{ text: item!.Title }}
            styles={listItemStyles()}
          />
        );
      }}
    />
  );
};

const SearchResults: React.FunctionComponent<ISearchResult> = (props: ISearchResult) => {
  const { 
    results = []
  } = props;

  const resultsByCategory = results;

  const [activePivotCategory, setActivePivotCategory] = useState(searchType);
  const [currentCategoryResults, setCurrentCategoryResults] = useState<IPortalSearchResult[]>(
    flatten(resultsByCategory.map((result) => result.items)).sort((a, b) => b.ID.localeCompare(a.ID))
  );

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);
  const [overflowOption, setOverflowOption] = useState<OverflowOption | undefined>();
  const [resultsPerPage, setResultsPerPage] = useState<number>(6);
  const maxPages = Math.ceil((1.0 * currentCategoryResults.length) / resultsPerPage);
  const [totalPages, setTotalPages] = useState<number>(maxPages);

  const [indexedResults, setIndexedResults] = useState<IPortalSearchResult[]>(
    currentCategoryResults ? currentCategoryResults.slice(0, resultsPerPage) : []
  );

  const setActiveCategoryResults = () => {
    const splitCategories = activePivotCategory.split('_');
    const category = splitCategories && splitCategories.length > 0 ? splitCategories[0] : 'All';

    if (category === 'All') { 
      const uniqueSortedResults = resultsByCategory
  .map((result) => result.items)  // Step 1: Get items from each category
  .flat()  // Step 2: Flatten the array to make it a single array
  .filter((value, index, self) => 
    index === self.findIndex((t) => t.ID === value.ID)  // Step 3: Ensure unique ID
  );

      setCurrentCategoryResults(
        flatten(uniqueSortedResults)
      );
    } else {
      const categoryWithSpaces = category.replace(/(-)/g, ' ').trim();
      const newActiveResult =
        resultsByCategory && resultsByCategory.find((result) => result.category === categoryWithSpaces);
      newActiveResult && setCurrentCategoryResults(newActiveResult.items);
    }
  };

  const onPageChange = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<IComboBox | IDropdown>,
    pivot: number
  ) => {
    const newStartIndex = (pivot - 1) * resultsPerPage;
    const results = getUpdatedResults(newStartIndex);

    setCurrentPage(pivot);
    setCurrentRowIndex(newStartIndex);
    setTotalPages(Math.ceil((1.0 * currentCategoryResults.length) / resultsPerPage));
    setIndexedResults(results);
  };

  const getUpdatedResults = (startIndex: number) => {
    if (startIndex <= 1) startIndex = 0;
    const endRow = startIndex + resultsPerPage;

    return currentCategoryResults ? currentCategoryResults.slice(startIndex, endRow) : [];
  };

  const onPivotChanged = (item?: PivotItem, _ev?: React.MouseEvent<HTMLElement>) => {
    if (item && activePivotCategory !== item.props.itemKey) {
      setActivePivotCategory(item.props.itemKey || '');
    }
  };

  const handlePagePivotClick = (
    pivot?: PivotItem | undefined,
    _ev?: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ): void => {
    const currentPivot = parseInt((pivot && pivot.props.headerText) || '1');
    const newStartingRow = (currentPivot - 1) * resultsPerPage;
    const results = getUpdatedResults(newStartingRow);

    setCurrentPage(currentPivot);
    setCurrentRowIndex(newStartingRow);
    setIndexedResults(results);
  };

  const handleOverflowClick: NonNullable<IPaginationProps['overflowButtonProps']>['onClick'] = (_event) => {
    const updateOverflow = overflowOption ? undefined : 'textfieldAndPageDropdown';
    setOverflowOption(updateOverflow);
  };

  const getResultsRangeText = () => {
    const endOfRowNumber = currentRowIndex + resultsPerPage;
    const endOfRow = endOfRowNumber > currentCategoryResults.length ? currentCategoryResults.length : endOfRowNumber;
    return {
      resultsRange: `${currentRowIndex + 1} - ${endOfRow}`,
      totalResultsPerCategory: currentCategoryResults.length
    };
  };

  const paginationProps: IPaginationProps = {
    pageControls: {
      currentPage: currentPage,
      pages: totalPages,
      overflowOption: overflowOption,
      onPivotClick: handlePagePivotClick
    },
    onPageChange: onPageChange,
    overflowButtonProps: {
      onClick: handleOverflowClick
    }
  };

  useEffect(() => {
    setCurrentCategoryResults(flatten(resultsByCategory.map((result) => result.items)));
  }, [resultsByCategory]);

  useEffect(() => {
    setActiveCategoryResults();
  }, [activePivotCategory]);

  useEffect(() => {
    onPageChange({} as React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<IComboBox | IDropdown>, 1);
  }, [currentCategoryResults]);

  return (
    <div style={searchResultsStyle}>
      <div style={{ margin: '0 16px' }}>
        <CategoryPivots
          activePivotCategory={activePivotCategory}
          onPivotChanged={onPivotChanged}
          resultsByCategory={resultsByCategory}
        />
        {currentCategoryResults.length > 0 ? (
          <ResultsBoundariesMessage metaInformation={getResultsRangeText()} searchText={searchText}/>
        ) : null}

        <ResultList indexedResults={indexedResults}/>
        {currentCategoryResults.length > 6 ? <Pagination {...paginationProps} /> : null}
      </div> 
    </div>
  );
};
// -- END -- Main search results pattern

const props = {  
  hasError: false,
  isEmpty: false,
  searchText: '',
  theme: CoherenceTheme,
  results:  resultsByCategory,
  styles: {
    root: {
      padding: '12px 16px 12px 16px'
    }
  }
}; 
 
return (
  <div  style={{ 
    minHeight: '100vh' //this added to adjust footer to screen height
  }} > 

<div style={searchResultsStyle}>
      <div style={{ margin: '0 16px' }}>
       <HeaderSearchResults goBack={goBack} />

       { isLoaded && <CoherenceLoading primaryText={" "}></CoherenceLoading>}
       { !isLoaded &&  hasError ? (
         <EmptyState
           headingText={`No Results found ${searchText.trim().length > 0 ? `for '${searchText}'` : ''}`}
           descriptionText="Try adjusting your search or pivots to find what you're looking for"
           type={EmptyStateIconType.EMPTY}
         />
     ) : null}
        </div>
  </div>
  {!isLoaded && !hasError ? <SearchResults {...props} />:null} 
 </div>
   );
 };