import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, loadTheme } from '@fluentui/react';
import App from "./App";
import {
  CoherenceTheme,
} from "@coherence-design-system/styles";
// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

// Allowed tenant IDs
const allowedTenants = [
    "72f988bf-86f1-41af-91ab-2d7cd011db47"
];

// Register service worker
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          console.log('Service Worker registered:', registration);
        })
        .catch(error => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.handleRedirectPromise()
    .then((tokenResponse) => {
        if (!tokenResponse) {
            const accounts = msalInstance.getAllAccounts();
            if (accounts.length === 0) {
                // No user signed in, redirect to login
                msalInstance.loginRedirect({
                    scopes: ["User.Read"]
                });
            }
        } else {
            const idTokenClaims = tokenResponse.idTokenClaims as any; // Access token claims
            const tenantId = idTokenClaims?.tid;

            // Validate the tenant ID
            if (!allowedTenants.includes(tenantId)) {
                console.error("Unauthorized tenant");
                msalInstance.logoutRedirect(); // Log out unauthorized tenants
            } else {
                console.log(`Authorized tenant: ${tenantId}`);
            }
        }
    })
    .catch(err => {
        console.error("Error during authentication: ", err);
    });

msalInstance.addEventCallback((event: EventMessage) => {
    if ((event.eventType === EventType.LOGIN_SUCCESS && event.payload) || 
        (event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS && event.payload)) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <Router>
        <ThemeProvider  theme={CoherenceTheme}>
          <MsalProvider instance={msalInstance}>
              <App />
          </MsalProvider>
        </ThemeProvider>
    </Router>
);
