import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType } from "@coherence-design-system/controls";
import { CommandBar, DetailsListLayoutMode, FontIcon, ICommandBarItemProps, Link, Pivot, PivotItem, TooltipHost } from "@fluentui/react";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { DOMEApiHelper, isEmpty } from "../../../utils/DOMEApiHelper";
import { GetUserRole } from "../../../utils/GetUserRole" 
import ToastMessage from "../../../shared/ToastMessage/ToastMessage";
import UtilDta from "../../../utils/UtilCommon.json";
import { NotificationPivotOptions } from "../../../shared/AppHeader/AppHeader.utils";
import CustomPagination from "../../../shared/CutomPagination/CustomPagination";
import CustomGridControls from "../../../shared/CustomGridControls/CustomGridControls"; 
import Accordion from "../../../shared/Accordion/Accordion";
import { useNavigate } from "react-router-dom";
import MyItems from "./MyItems";

const PORItems: React.FunctionComponent = () => {
  const [userRole, setUserRole] = useState(GetUserRole());
  const [isLoaded, setIsLoaded] = useState(false);
  const [LoadText, setLoadText] = useState<string>("");
  const [porItemsData, setPORItemsData] = useState<any>([]);
  const [catalogCategory, setCatalogCategory] = useState<any>([]);
  const [porState, setPORState] = useState<any>([]);

  //toaster code Start
  const [toastType, setToastType] = useState<CoherenceNotificationType>(CoherenceNotificationType.SUCCESS);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastTitle, setToastTitle] = useState<string>("");
  const [showToast, setShowToast] = React.useState<boolean>(false);
  //toaster code end

  let navigate = useNavigate();
  const getColumns = (): CoherenceDataGridColumn<any>[] => {
    const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>, item: any) => {
      const Id = item.PORID;
      const url = `/PORDetails/${Id}`;
      if (event.ctrlKey || event.metaKey) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }
    };
    var advancedColumns: CoherenceDataGridColumn<any>[] = [

      {
        key: 'PORID',
        name: 'POR ID',
        fieldName: 'PORID',
        type: 'link',
        url: 'url',
        isResizable: true,
        minColumnWidth: 70,
        maxWidth: 70,
        ariaLabel: 'Data Value',
        data: [],
        onRender: (item: any) => {
           return (
            <Link onClick={(event) => handleLinkClick(event as React.MouseEvent<HTMLAnchorElement>, item)}>
              {item.PORID}
            </Link>
          );
        },
        iconName: appendSortIcon('PORID'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'Title',
        name: 'Title',
        fieldName: 'Title',
        type: 'string',
        isResizable: true,
        minColumnWidth: 260,
        maxWidth: 260,
        ariaLabel: 'Title',
        data: [],
        onRender: (item: any) => {
          return <TooltipHost content={item.Title}>
            <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
              {item.Title}</span>
          </TooltipHost>
        },
        iconName: appendSortIcon('Title'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'PORStateName',
        name: 'POR State',
        fieldName: 'PORStateName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'State',
        className: 'cellLeftAligned customGridCell',
        onRender: (item: any) => {
          return <span style={{ fontSize: 14, color: 'rgb(50, 49, 48)' }}>
                <FontIcon iconName="RadioBullet" style={{ color: isEmpty(item.BulletColorUI)? 'black' : item.BulletColorUI, fontWeight: 500 }}></FontIcon>
              {item.PORStateName}</span>
        },
        data: [],
        iconName: appendSortIcon('PORStateName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'PrimaryContact',
        name: 'Primary Contact',
        fieldName: 'PrimaryContact',
        type: 'persona',
        isResizable: true,
        minColumnWidth: 220,
        maxWidth: 220,
        ariaLabel: 'Primary Contact',
        imageUrl: 'imageUrl',
        personaAlt: (item) => `Profile image for ${item.fullName}`,
        data: [],
        iconName: appendSortIcon('PrimaryContact'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ActionRequired',
        name: 'Action Required',
        fieldName: 'ActionRequired',
        type: 'number',
        isResizable: true,
        ariaLabel: 'Action Required',
        className: 'cellLeftAligned customGridCell',
        minColumnWidth: 120,
        maxWidth:120,
        onRender: (item: any) => {
            switch (item.ActionRequiredName) {
                case UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key:
                    {
                        return <span>
                            <FontIcon iconName="Recent" style={{ color: 'rgba(215, 59, 2, 1)', fontWeight: 500 }}></FontIcon>
                            {' ' + UtilDta.filter((data: any) => data.id.includes("Pending"))[0].key}</span>
                    }
               
            }
        },
        data: [],
       
        iconName: appendSortIcon('ActionRequiredName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'ServiceLineData',
        name: 'Service Line',
        fieldName: 'ServiceLineData',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'Service Line',
        data: [],
        iconName: appendSortIcon('ServiceLineData'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'CatalogCategoryName',
        name: 'Category',
        fieldName: 'CatalogCategoryName',
        type: 'string',
        isResizable: true,
        minColumnWidth: 120,
        maxWidth: 120,
        ariaLabel: 'Category',
        data: [],
        iconName: appendSortIcon('CatalogCategoryName'),
        iconClassName: "customSortIcon"
      },
      {
        key: 'TargetCompletionDate',
        name: 'Target Completion Date',
        fieldName: 'TargetCompletionDate',
        type: 'date',
        isResizable: true,
        minColumnWidth: 180,
        maxWidth: 180,
        ariaLabel: 'Target Completion Date',
        data: [],
        onRender: (item: any) => {
          return <span style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'left', fontWeight: 500 }}>
            {dayjs(item.TargetCompletionDate).format('MM/DD/YYYY')}</span>
        },
        iconName: appendSortIcon('TargetCompletionDate'),
        iconClassName: "customSortIcon"
      },
    ];  
    return advancedColumns;
  };

  const requestOptionsGETALL = {
    method: 'GET'
  };
 
  const LoadData = (text: string) => {
    setIsLoaded(true);
    setLoadText(text);
    setPORItemsFilteredData([]);
    setActiveTab(NotificationPivotOptions.ALLITEMS);
   
    DOMEApiHelper('PORItems/GetAllPORItemsDataAsync', requestOptionsGETALL)
      .then(res => {

        if (res !== undefined && res.length > 0 && res[0]["PORItemsList"].length > 0) {
          let porItemsList = res[0]["PORItemsList"];
          porItemsList.forEach((item: any, index: any) => {
            item.Id = item.PORID;
            item.PrimaryContact = item.PrimaryContactDisplayName ? item.PrimaryContactDisplayName : item.PrimaryContactObjectId;
            if(isEmpty(item.AssignedToUserName)){
              item.AssignedToUserName= item.AssignedTo;
            }
            if(isEmpty(item.IsConfidential)){
              item.IsConfidential= false;
            }
            item.Title = item.IsConfidential ? `Confidential - ${item.PORID}` : item.Title;

          });          
         
          setPORItemsData(porItemsList);
          setPORItemsBeforeFilterData(porItemsList);

          //sort bydefault 
          if(sortColumnDetails !== undefined && sortColumnDetails.length > 0){
            sortDefault(res[0]["PORItemsList"]);
          }
          else {
            resetPagination();
          } 
          GetPendingActionRequiredPORIdList(porItemsList);
        }
        setIsLoaded(false);
        setLoadText(""); 
      }).catch(error => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching POR Items data");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
  }

  const GetPendingActionRequiredPORIdList = (porItemsList:any) => {
    setPORItemsFilteredData([]); 
    DOMEApiHelper('PORItems/GetPendingActionRequiredPORIdList', requestOptionsGETALL)
      .then(res => {      
        if (!isEmpty(res) && !isEmpty(res.ActionRequiredPORIds)) {
          const pendingKey = UtilDta.find((data: any) => data.id.includes("Pending"))?.key || "";        
          porItemsList = porItemsList.map((item: any) => ({
            ...item,
            ActionRequiredName: res.ActionRequiredPORIds.includes(item.PORID) ? pendingKey : "",
          })); 
          setPORItemsData(porItemsList);
          setPORItemsBeforeFilterData(porItemsList);
        }
      }).catch(error => {
        setLoadText("");
        setIsLoaded(false);
        setToastTitle("Error while fetching GetPendingActionRequiredPORIdList");
        setToastMessage(error.toString());
        setToastType(CoherenceNotificationType.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 5000);
      });
    }
  
    useEffect(() => {
      setIsLoaded(true);
    LoadData("Loading data");
    // eslint-disable-next-line 
  }, [setPORItemsData]);
  //Pagination code start   
  const [porItemsPaginatedData, setPORItemsPaginatedData] = useState<any>([]);
  const [refreshPagination, setRefreshPagination] = useState<boolean>(false);
  const updatePaginationData = (data: any) => {
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setPORItemsPaginatedData(data);
    }
  }

  const getDataCountByActionType = (inputType: any) => {
    if (inputType === NotificationPivotOptions.ALLITEMS) {
      return isFilterApplied ? porItemsFilteredData.length : porItemsBeforeFilterData.length;
    }
  }
  const resetPagination = () => {
    setRefreshPagination(refreshPagination ? false : true);
  }
  //Pagination code end 

  //Pivot related code start
  const [activeTab, setActiveTab] = useState<NotificationPivotOptions>(NotificationPivotOptions.ALLITEMS);
  const handlePivotChange = (item: any) => {
    if (item.props.itemKey === NotificationPivotOptions.ALLITEMS) {
      setPORItemsData(isFilterApplied ? porItemsFilteredData : porItemsBeforeFilterData);
      if (sortColumnDetails !== undefined && sortColumnDetails.length > 0)
        sortDefault(isFilterApplied ? porItemsFilteredData : porItemsBeforeFilterData)  //sort bydefault     
      else resetPagination();
    }
    setActiveTab(item.props.itemKey);
  };
  //Pivot related code end


  //Filter code start  
  const [porItemsBeforeFilterData, setPORItemsBeforeFilterData] = useState<any>([]);
  const [porItemsFilteredData, setPORItemsFilteredData] = useState<any>([]);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const onApplyFilter = (filteredArray: any) => {
    setIsFilterApplied(true);
    setPORItemsFilteredData(JSON.parse(JSON.stringify(filteredArray)));
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setPORItemsData(filteredArray);
    }
    sortDefault(filteredArray); //sort bydefault
    resetPagination();
  };

  const onClearFilter = () => {
    setIsFilterApplied(false);
    setPORItemsFilteredData([]);
    if (activeTab === NotificationPivotOptions.ALLITEMS) {
      setPORItemsData(porItemsBeforeFilterData);
    }
    resetPagination();
  }

  const createFilterColumns = () => {
    return [
      { "fieldName": "PORID", "label": "PORID", "type": "searchTextBox", "options": [],"additionalData":{"minChar":2} },
      { "fieldName": "Title", "label": "Title", "type": "searchTextBox", "options": [],"additionalData":{"minChar":2} },
      { "fieldName": "PORStateName", "label": "POR State", "type": "multiselectDropdown", "options": [],"Default":["In Progress","Candidate"] },
      { "fieldName": "PrimaryContact", "label": "Primary Contact", "type": "multiselectDropdown", "options": [] ,"Default":[sessionStorage.getItem("localAccountName")]},
      { "fieldName": "CatalogCategoryName", "label": "Catalog Category", "type": "multiselectDropdown", "options": [] },
      { "fieldName": "TargetCompletionDate", "label": "Target Completion Date", "type": "date", "fromTargetCompletionDate": null, "toTargetCompletionDate": null },
      { "fieldName": "ServiceLineData", "label": "Service Line","type": "multiselectDropdown", "options": [] },
      {"fieldName": "ActionRequiredName", "label": "Action Required", "type": "multiselectDropdown", "options": [] }
    ];
  }
  //Filter code end




  //Sort code start
  
  const [sortColumnDetails, setSortColumnDetails] = useState<any[]>([
    
    //In this Order,  First TargetCompletionDate will sort first and then with in sorteddata , the ActionRequiredName  will  sort 
    { columnName: "TargetCompletionDate", sortType: "asc" }, //Secondary sort
    { columnName: "ActionRequiredName", sortType: "desc" },  //primary sort
  ]);
  
  const createSortColumns = () => {
    return [
      { "fieldName": "PORID", "label": "PORID" },
      { "fieldName": "Title", "label": "Title" },
      { "fieldName": "PORStateName", "label": "POR State" },
      { "fieldName": "PrimaryContact", "label": "Primary Contact" },
      { "fieldName": "CatalogCategoryName", "label": "Catalog Category" },
      { "fieldName": "TargetCompletionDate", "label": "Target Completion Date" },
      { "fieldName": "ServiceLineData", "label": "Service Line" },
      { "fieldName": "ActionRequiredName", "label": "Action Required" }
    ]
  };
  const sortDefault = (data: any) => {    
    if (sortColumnDetails.length > 0) {
      sortColumnDetails.forEach((sortColumn: any) => {
        const { columnName, sortType } = sortColumn;
        onSorted(columnName, sortType, data,true);
      });
    }
  }
    const appendSortIcon = (columnName: string): any => {
        if (sortColumnDetails.length > 0) {
            const sortColumn = sortColumnDetails.find((sortColumn: any) => sortColumn.columnName === columnName);
            if (sortColumn) {
                return sortColumn.sortType === "asc" ? "Ascending" : "Descending";
            }
        }
    }
  const onSorted = (columnName: string, sortType: string, porItems?: any,defaultSort = false): void => {    
    if (defaultSort) {
      setSortColumnDetails((prevSortColumnDetails) => {
        const updatedSortColumnDetails = [...prevSortColumnDetails];
        const existingSortColumn = updatedSortColumnDetails.find((sortColumn: any) => sortColumn.columnName === columnName);
        if (existingSortColumn) {
        existingSortColumn.sortType = sortType;
        } else {
        updatedSortColumnDetails.push({ columnName, sortType });
        }
        return updatedSortColumnDetails;
      });
      } else {
      setSortColumnDetails([{ columnName, sortType }]);
      }
      

      let sortedData = (porItems !== undefined ? porItems : porItemsData).sort((a: any, b: any) => {
        if (a[columnName] > b[columnName]) {
          return sortType === 'asc' ? 1 : -1;
        }
        if (a[columnName] < b[columnName]) {
          return sortType === 'asc' ? -1 : 1;
        }
        return 0;
      });

        setPORItemsFilteredData(JSON.parse(JSON.stringify(sortedData)));
        setPORItemsData(JSON.parse(JSON.stringify(sortedData)));
      resetPagination();
    }
    
    //Sort code end

  const onClickCommandBarsButtonsEvents = (source: any) => {
    if(source=='Create New'){
      navigate("/CreatePOR");
    }
    }
  const generateButtons = (): any => {
    const _items: ICommandBarItemProps[] = [
      {
        key: 'Create New',
        text: 'Create New',
        iconProps: { iconName: 'Add' } 
      }];
    return _items;
  }

  return (
    <> 
<div  style={{ 
    minHeight: '100vh' //this added to adjust footer to screen height
  }}
> 
      <h2>Plan of Record</h2>

      <Accordion title="POR Items" isExpandDefault={true}>
     
       <CustomGridControls defaultFilterSessionKey="PORItemsDefaultFilter" onClickAdditionalControls={onClickCommandBarsButtonsEvents} additionalControls={generateButtons()}  filterColumns={createFilterColumns()} sortColumns={createSortColumns()} data={porItemsBeforeFilterData} onApplyFilter={onApplyFilter} onSorted={onSorted} onClearFilter={onClearFilter} />
       
        {showToast && <ToastMessage showToast={showToast} type={toastType} message={toastMessage} title={toastTitle}  ></ToastMessage>}
        <Pivot onLinkClick={handlePivotChange} selectedKey={activeTab}>
          <PivotItem
            headerText={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
            itemKey={NotificationPivotOptions.ALLITEMS}
            ariaLabel={"All Items (" + getDataCountByActionType(NotificationPivotOptions.ALLITEMS) + ")"}
          >
            <div style={{ minHeight: 425, height: 425, maxHeight: 425 }}>
              {!isLoaded ? <CoherenceDataGrid
                listConfig={getColumns()}
                data={porItemsPaginatedData}
                isScrollable={true}
                isSortable={false}
                sortByDefault={false}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                ariaLabel={"POR All Items List"}
              ></CoherenceDataGrid>
                : <CoherenceLoading primaryText={LoadText}></CoherenceLoading>}
            </div>
          </PivotItem>
        </Pivot>
        <CustomPagination refreshPagination={refreshPagination} updatePaginationData={updatePaginationData} data={porItemsData} pageSize={8}></CustomPagination>
      </Accordion>

      <MyItems></MyItems>
      </div>
                
    
    </>
  );
};

export default PORItems; 