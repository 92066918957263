import { useEffect, useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { settingsStyles } from "./AppHeader.styles";
import { CoherenceDataGrid, CoherenceDataGridColumn, CoherenceLoading, CoherenceNotificationType, Template } from "@coherence-design-system/controls";
import { CommandBar, DetailsListLayoutMode, FontIcon, IComboBox, IComboBoxOption, ICommandBarItemProps, Link, Persona,IContextualMenuProps,IPanel,Panel,PersonaSize,IContextualMenuItemProps, Pivot, PivotItem, ResizeGroup, TooltipHost ,  FontSizes,
  FontWeights,IconButton,ITheme,DefaultPalette, createTheme, ThemeProvider} from "@fluentui/react";
import { GetUserRole } from "../../utils/GetUserRole" 
import {  Category, EnhancedSearch, ICategory, IResult } from "@coherence-design-system/enhanced-search";
import { Header, HeaderButton, NotificationBadge, getTotalNewNotifications, usePanels, useResizeGroup } from '@coherence-design-system/header';
import { ProfileCenter } from "@coherence-design-system/profile-center";
import { FeedbackCenter } from "@coherence-design-system/feedback-center";
import { DOMEApiHelper, isEmpty } from "../../utils/DOMEApiHelper";
import ToastMessage from "../ToastMessage/ToastMessage"; 
 

 export const AppHeader = () => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [UserName, setName] = useState("");
  const [Email, setEmail] = useState("");
  const location = useLocation();
  const [isHeaderSearch, setIsHeaderSearch] = useState(false);
  const defaultUserName = sessionStorage.getItem("localAccountName");
  const defaultUserID = sessionStorage.getItem("localAccountId"); 
  useEffect(() => {
      if (account) {
        if(account.name) {
          setName(account.name);
          sessionStorage.setItem("localAccountName",account.name);
          sessionStorage.setItem("localAccountId",account.localAccountId);
          sessionStorage.setItem("localAccountEmailId",account.username);
        }
        if(account.username) {
          setEmail(account.username);
        }
      } else {
          setName("");
          setEmail("");
      }
      const routerName = location.pathname.split('/')[1];
      if(routerName == "PORItems") {
        
        setIsHeaderSearch(true);
      }
      else {
        setIsHeaderSearch(false);
      }
  }, [account,location]);
 

    //header search code start
  // Define dropDownList
  const dropDownList: IComboBoxOption[] = [
    {key:'All', text: 'All'},
    {key:'PORID', text: 'PORID'},
    {key:'Title', text: 'Title'},
    {key:'MILAID', text: 'MILAID'},
    {key:'CTID', text: 'CTID'},
    {key:'OneMapID', text: 'OneMapID'},
    {key:'PFAM', text: 'PFAM'},
    {key:'SKU', text: 'SKU'}
  ];
  

  //  const categorizedNotifications = getCategorizedNotifications(theme);
  const [activeScope, setActiveScope] = React.useState(dropDownList[0].text);
  const [searchText, setSearchText] = React.useState('');
  const profileCenterRef = React.useRef<IPanel>(null);
  const feedbackCenterRef = React.useRef<IPanel>(null);
  const { currentPanel, openPanel, closePanel } = usePanels();
  const navigate = useNavigate();
 
    const onSaveSearch = (searchTextValue: string) => {
      let propsData= {
        "searchText": searchTextValue,
        "searchType": activeScope
     } 
     //Sending this props data to App.tsx file - to communicate with HeaderSearchResults file 
    navigate(`/SearchResult`,{ state: { searchData: propsData } });  
    onSearchClear();
    }

  

  const onSearchChange = (_event?: React.ChangeEvent<HTMLInputElement>, newSearchText?: string) => {
    setSearchText(newSearchText || '');
  };
  const onSearchClear = () => {
    setSearchText('');
  };
 // FeedBack/OCV Center
 const InitialFeedbackDataNoSurface = {
   callbackFunctions: {
     onDismiss: (_isFeedbackSent?: boolean) => {}
   }
 }; // Define or import this object as needed
 const ocvOptions = { ...InitialFeedbackDataNoSurface };
 ocvOptions.callbackFunctions.onDismiss = (_isFeedbackSent?: boolean) => {
   closePanel();
 };
 const profileProps = {
  Title: "Profile",
  persona: {
    imageUrl: "",
    text: UserName,
    secondaryText: Email,
  },
  actions: [ 
    {
      children: 'View account',
      onClick: ():any => { 
        window.location.href = "https://myaccount.microsoft.com";
      },
    }
    
  ]
};
const _items = [
  {
    key: 'feedback',
    icon: 'Feedback',
    iconProps: { iconName: 'Feedback' },
    ariaLabel: 'Feedback',
    text: 'Feedback',
    onClick: ():any => { 
      return window.open("https://forms.office.com/r/VbPE5HZFuu",'Feedback Form');
    },
  },  
];
  const onSelectDropdown = React.useCallback((event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number) => {
      setActiveScope(option!.text);
    },
    []
    );
  const formattedData = {
    primary: _items,
    overflow: []
  };
const { onReduceData, onGrowData, onRenderData } = useResizeGroup({ closePanel, currentPanel });

const onSaveDataValid1 = (data:any) => {
  console.log("Data is valid", data);
}; 
const SharedEnhancedSearch =  (
  <EnhancedSearch
    minHighlightThreshold={1}
    id="standalone-enhancedsearch-with-searchscope"
    onSearch={onSaveSearch}
    onChange={onSearchChange}
    onClear={onSearchClear}
    value={searchText}
    isSearchInHeader={false}
    autoComplete="off"
    searchScopeOptions={{
      autoComplete: 'off',
      onItemSelect: onSelectDropdown,
      selectedKey: activeScope,
      scopes: dropDownList.filter((scope: any) => scope.key !== 'All'),
    }}
  /> 
);

  // header search code end
  return (
    <>
      <div style={{ height: '48px' }} role="banner">
        <Header 
          title="DOME"  
          showHamburger={false}
        >
          {isHeaderSearch && <Template slot="search">{SharedEnhancedSearch}</Template>}
          <Template slot="collapsible-triggers">
            <ResizeGroup
              styles={{ root: { background: 'transparent', height: '100%' } }}
              data={formattedData}
              onReduceData={onReduceData}
              onGrowData={onGrowData}
              onRenderData={onRenderData}
            />
          </Template>
          <Template slot="static-triggers">
            <TooltipHost content={'profile'} id={'profile'}>
              <HeaderButton
                onClick={() => openPanel(profileCenterRef)}
                aria-labelledby={'profile'}
              >
                <Persona hidePersonaDetails={true} size={PersonaSize.size28} {...profileProps.persona} />
              </HeaderButton>
            </TooltipHost>
          </Template>
        </Header>
      </div>
      {/* Panels live outside the header. */}
      <>
        <ProfileCenter
          componentRef={profileCenterRef}
          isLightDismiss={true}
          onDismiss={() => closePanel()}
          {...profileProps}
        />
        <FeedbackCenter
          componentRef={feedbackCenterRef}
          onDismiss={() => closePanel()}
          ocvOptions={ocvOptions}
          isOpen={Boolean(currentPanel === feedbackCenterRef)}
        />
      </> 
    </>
     
  );
};
export default AppHeader; 